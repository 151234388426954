export const uat = {
  /**
   * Allowed Values
   * ooa (order of articles), subcategories, still_entyvio,
   * ibd_chart, tutorial, message_board, opf (online patient forum)
   * * tested : subcategories, message_board, opf, tutorial, still_entyvio, ibd_chart
   */
  templateID: 'd-4b8459da5825462b94d15fa5afbdfa1f',
  cookieOneTrust: 'https://cdn.cookielaw.org/consent/155491e9-a3db-439b-8a89-c9276d4259b9/OtAutoBlock.js',
  cookieCdnScript: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  cookieId: '155491e9-a3db-439b-8a89-c9276d4259b9',
  enhancements: ['ooa', 'subcategories', 'still_entyvio', 'ibd_chart', 'tutorial', 'message_board', 'opf'],
  loginRedirect: 'https://stage-v1.custom.ibdoktor.hu',
  loginRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api',
  oktaClientId: '0oarslgx4eeFN9LWT0h7',
  appRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-sf-pr-exp/v1/api/', // -changed
  registerRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/user/register',
  loginClientId: '6f4f27221ad449f591238fef21f18ae1',
  loginClientSecret: '2f93e83F0e814B5E9C4Ae146b30d6610',
  clientId: 'de5882fcb6854c228fa539033cb45af1', // used with app root
  clientSecret: 'F2A821ba8F5c409b82581BF0EF45098b',
  categoryRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/',
  grantType: 'authorization_code',
  state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  nonce: 'nonce-285shshkiooo989',
  xClient: 'mobile',
  redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  sendRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/email/send', // -changed
  recoveryPasswordRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/recoveryPassword',
  relayStateRecovery: 'https://stage-v1.custom.ibdoktor.hu/password_reset/init',
  sendMailClientID: '989762fda74a498889708a0d8672db15', // sendRoot
  sendMailClientSecret: 'eF1865a4F263405cabC2d3EfDdbD60f4',
  verifyToken: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verifyToken',
  resetPassword: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/resetPassword',
  verifyUser: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verify',
  userLogRecord: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/user/log/record',
  mulesoft: {
    baseURI: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/user/status?emailAddress=',
    clientID: '989762fda74a498889708a0d8672db15',
    clientSecret: 'eF1865a4F263405cabC2d3EfDdbD60f4'
  },
  supported_cultures: ['hu-HU'],
  countryClass: '',
  logo: '/images/fywy-app-icon-1024.png',
  entyvioLogo: '/images/entyvio@2x.png',
  leftArrow: '/images/left.png',
  rightArrow: '/images/right.png',
  pinImage: '/images/pinned.png',
  medicationIcon: '/images/icon-injection-reminder@2x.png',
  injectionIcon: '/images/icon-injection-tracker@2x.png',
  surveyIcon: '/images/survey_2x.png',
  homepageCustomStyle: {},
  country: 'HU',
  language: 'hu',
  colors: {
    primaryColor: '#51B1BF',
    borderColor: '#51B1BF',
    footer: 'rgb(81 177 191 / 30%)',
    iconColor: '#51B1BF',
    title: '#51B1BF',
    siderColor: '#e2f5f7',
    reminderTitle: '#0e6a7f',
    reminderIcon: '#ecaa00',
    cardColor: '#E2F5F7',
    cardTitle: '#0E6A7F',
    cardIcon: '#84d8e0',
    subtitle: '#4C4948',
    menuColor: '#E2F5F7',
    mobileNavigationColor: '#51B1BF',
    messageBoard: '#E2F5F7D9',
    mobileMessageBoard: '#E2F5F7',
    messageCard: '#E2F5F7',
    mobileMessageCard: '#F5FEFF',
    headerLandingText: '#106A7F',
    hcpLandingText: '#4B9AA9'
  },
  medicationDateFormat: 'HH:mm a',
  entyvioLeaflets: true,
  hideSelector: true,
  diskModal: false,
  profile: {
    treatmentFormat: 'YYYY-MM-DD',
    contactDetails: true,
    mobilePhone: true,
    address: true
  },
  landing: {
    selector: false,
    button: false,
    hcpportal: false,
    disclaimer: false,
    promoCode: 'block',
    video: {
      visible: false,
      image: null
    },
    googlePlay: 'https://play.google.com/store/apps/details?id=com.takeda.foryouwithyou.hu',
    appleStore: 'https://apps.apple.com/us/app/for-you-with-you/id1532242595',
    hcpLink: 'https://foryouwithyou.hartehanks.com/sessions/new?culture=',
    logo: '/images/landing/hu-ibdoctor.png',
    background: '/images/landing/hu-banner@2x.png',
    mobileBackground: '/images/landing/mobile_hr_header@2x.png',
    phone: {
      en: '/images/landing/en-mobile@2x.png',
      hu: '/images/landing/hu-mobile@2x.png'
    },
    icons: {
      appointment: '/images/appointment_tracker_2x.png',
      disk: null,
      // disk: '/images/icon-IBD-disk@2x.png',
      map: '/images/map-menu-icon@2x.png',
      recipes: '/images/Recipes@2x.png',
      videos: '/images/Videos@2x.png'
    },
    languages: {
      one: {
        code: '',
        culture: ''
      },
      two: {
        code: '',
        culture: ''
      }
    }
  },
  promo: {
    video: true,
    forum: true,
    stayConnected: true,
    images: {
      hu: {
        welcome: {
          picture: '/images/promo/desktop/desktop-1.png',
          mobile: '/images/promo/desktop/iphone.png'
        },
        content: '/images/promo/desktop/content.png',
        disk: '/images/promo/desktop/disk.png',
        trackers: '/images/promo/desktop/trackers.png',
        forum: '/images/promo/desktop/forum.png',
        connected: '/images/promo/desktop/connected.png'
      }
    }
  },
  footer: {
    privacy: 'https://www.takeda.com/hu-hu/adatvedelmi-tajekoztato/',
    terms: 'https://www.takeda.com/hu-hu/hasznalati-feltetelek/'
  },
  cardImages: {
    gastro: '/images/Gastro_Doctor.png',
    test: '/images/medical_test.png',
    call: '/images/nurse_call.png',
    visit: '/images/nurse_visit.png',
    practitioner: '/images/Doctor.png',
    intravenous: '/images/IV.png',
    default: '/images/appointment_tracker_2x.png'
  },
  injectionCard: {
    leftabdomen: '/images/injection-tracker/HB_SMALL_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_SMALL_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_SMALL_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_SMALL_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_SMALL_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_SMALL_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_SMALL_Track_Unconfirmed.jpg'
  },
  injectionTracker: {
    pickerValidation: true,
    leftabdomen: '/images/injection-tracker/HB_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_Track_Default.jpg'
  },
  goHomeStyle: {
    display: 'none'
  },
  hcpStyle: {},
  types: {
    intravenous: [
      {
        code: 'INTRAVENOUS_INFUSION'
      },
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    subcutaneous: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'NURSE_SUPPORT_CALL'
      },
      {
        code: 'NURSE_SUPPORT_VISIT'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ],
    other: [
      {
        code: 'GASTRO_SPECIALIST'
      },
      {
        code: 'GENERAL_PRACTITIONER'
      },
      {
        code: 'MEDICAL_TEST'
      },
      {
        code: 'CALPROTECTIN_TEST'
      }
    ]
  }
}
