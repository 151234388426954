export const uat = {
  /**
   * Allowed Values
   * ooa (order of articles), subcategories, still_entyvio,
   * ibd_chart, tutorial, message_board, opf (online patient forum)
   * * tested : subcategories, message_board, opf, tutorial, still_entyvio, ibd_chart
   */
  enhancements: [
    'ooa',
    'subcategories',
    'still_entyvio',
    'ibd_chart',
    'tutorial',
    'message_board'
    // 'opf'
  ],

  // loginRedirect: 'https://stage-v1.ibd.foryouwithyou.be',
  // loginRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api',
  // oktaClientId: '0oarslgx4eeFN9LWT0h7',
  // appRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/api/',
  // loginClientId: '6f4f27221ad449f591238fef21f18ae1',
  // loginClientSecret: '2f93e83F0e814B5E9C4Ae146b30d6610',
  // clientId: '35114b55a0d54f66b49d27b0caf04dfb',
  // clientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // categoryRoot: 'https://api-eu-np.takeda.com/dev/entyvio-fywy-sf-api/',
  // grantType: 'authorization_code',
  // state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  // nonce: 'nonce-285shshkiooo989',
  // xClient: 'mobile',
  // redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  // activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  // sendRoot: 'https://api-eu-np.takeda.com/dev/entyvio-sendgrid-api/api/email/send',
  // recoveryPasswordRoot: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/recoveryPassword',
  // relayStateRecovery: 'https://stage-v1.ibd.foryouwithyou.be/password_reset/init',
  // sendMailClientID: '35114b55a0d54f66b49d27b0caf04dfb',
  // sendMailClientSecret: '679fd4F26d7A49B98eB455F53B3A9F5f',
  // verifyToken: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/verifyToken',
  // resetPassword: 'https://api-us-np.takeda.com/dev/security-takedaid-api/v1/user/resetPassword',
  templateID: 'd-5a0fb044c61943baa8b0e879b52c66b7',
  cookieOneTrust: 'https://cdn.cookielaw.org/consent/155491e9-a3db-439b-8a89-c9276d4259b9/OtAutoBlock.js',
  cookieCdnScript: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  cookieId: '155491e9-a3db-439b-8a89-c9276d4259b9',
  loginRedirect: 'https://stage-v1.ibd.foryouwithyou.be/login',
  loginRoot: 'https://api-us-np.takeda.com/uat/security-takedaid-api',
  oktaClientId: '0oarslgx4eeFN9LWT0h7',
  appRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-sf-pr-exp/v1/api/', // -changed
  registerRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/user/register',
  loginClientId: 'e6d44a4b0d584677a7d5579629642a0d',
  loginClientSecret: '38B4108b71b2402A90E81af4ed91699A',
  clientId: 'de5882fcb6854c228fa539033cb45af1', // used with app root
  clientSecret: 'F2A821ba8F5c409b82581BF0EF45098b',
  categoryRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/',
  grantType: 'authorization_code',
  state: 'state-296bc9a0-a2a2-4a57-be1a-d0e2fd9bb00601',
  nonce: 'nonce-285shshkiooo989',
  xClient: 'mobile',
  redirectRoot: 'https://takedaext.oktapreview.com/oauth2/default/v1/authorize',
  activeSession: 'https://takedaext.oktapreview.com/api/v1/sessions/me',
  sendRoot: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/email/send', // -changed
  recoveryPasswordRoot: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/recoveryPassword',
  relayStateRecovery: 'https://stage-v1.ibd.foryouwithyou.be/password_reset/init',
  sendMailClientID: '989762fda74a498889708a0d8672db15', // sendRoot
  sendMailClientSecret: 'eF1865a4F263405cabC2d3EfDdbD60f4',
  verifyToken: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/verifyToken',
  resetPassword: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/resetPassword',
  verifyUser: 'https://api-us-np.takeda.com/uat/security-takedaid-api/v1/user/verify',
  userLogRecord: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/user/log/record',
  mulesoft: {
    baseURI: 'https://api-us-aws-uat2.takeda.com/ddb-entyvio-fywy-exp/v1/user/status?emailAddress=',
    clientID: '989762fda74a498889708a0d8672db15',
    clientSecret: 'eF1865a4F263405cabC2d3EfDdbD60f4'
  },
  supported_cultures: ['nl-BE', 'fr-BE'],
  countryClass: '',
  logo: '/images/fywy-app-icon-1024.png',
  entyvioLogo: '/images/entyvio@2x.png',
  leftArrow: '/images/left.png',
  rightArrow: '/images/right.png',
  pinImage: '/images/pinned.png',
  medicationIcon: '/images/icon-injection-reminder@2x.png',
  injectionIcon: '/images/icon-injection-tracker@2x.png',
  surveyIcon: '/images/survey_2x.png',
  homepageCustomStyle: {},
  country: 'BE',
  language: 'nl',
  colors: {
    primaryColor: '#51B1BF',
    borderColor: '#51B1BF',
    footer: 'rgb(81 177 191 / 30%)',
    iconColor: '#51B1BF',
    title: '#51B1BF',
    siderColor: '#e2f5f7',
    reminderTitle: '#0e6a7f',
    reminderIcon: '#ecaa00',
    cardColor: '#E2F5F7',
    cardTitle: '#0E6A7F',
    cardIcon: '#84d8e0',
    subtitle: '#4C4948',
    menuColor: '#E2F5F7',
    mobileNavigationColor: '#51B1BF',
    messageBoard: '#E2F5F7D9',
    mobileMessageBoard: '#E2F5F7',
    messageCard: '#E2F5F7',
    mobileMessageCard: '#F5FEFF',
    headerLandingText: '#106A7F',
    hcpLandingText: '#4B9AA9'
  },
  medicationDateFormat: 'HH:mm',
  entyvioLeaflets: false,
  hideSelector: true,
  diskModal: true,
  profile: {
    treatmentFormat: 'DD-MM-YYYY',
    contactDetails: true,
    mobilePhone: true,
    address: true
  },
  landing: {
    selector: true,
    button: true,
    hcpportal: true,
    disclaimer: false,
    promoCode: null,
    video: {
      visible: true,
      image: {
        fr: '/images/landing/video-snip-fr.png',
        nl: '/images/landing/video-snip-nl.png'
      }
    },
    googlePlay: 'https://play.google.com/store/apps/details?id=com.takeda.foryouwithyou.be',
    appleStore: 'https://apps.apple.com/us/app/for-you-with-you/id1519971603',
    hcpLink: 'https://stage-v1.hcp.foryouwithyou.be?culture=',
    logo: '/images/landing/fywy-logo@2x.png',
    background: '/images/landing/default-banner@2x.png',
    mobileBackground: '/images/landing/mobile_header@2x.png',
    phone: {
      en: '/images/landing/en-mobile@2x.png',
      fr: '/images/landing/fr-mobile@2x.png',
      nl: '/images/landing/nl-mobile@2x.png'
    },
    icons: {
      appointment: '/images/appointment_tracker_2x.png',
      disk: '/images/icon-IBD-disk@2x.png',
      map: '/images/map-menu-icon@2x.png',
      recipes: '/images/Recipes@2x.png',
      videos: '/images/Videos@2x.png'
    },
    languages: {
      one: {
        code: 'FR',
        culture: 'fr-BE'
      },
      two: {
        code: 'NL',
        culture: 'nl-BE'
      }
    }
  },
  promo: {
    video: false,
    forum: false,
    stayConnected: true,
    images: {
      fr: {
        welcome: {
          picture: '/images/promo/desktop/BE/welcome-fr.png',
          mobile: '/images/promo/desktop/BE/iphone-fr.png'
        },
        content: '/images/promo/desktop/BE/content-fr.png',
        disk: '/images/promo/desktop/BE/disk-fr.png',
        trackers: '/images/promo/desktop/BE/trackers-fr.png',
        forum: null,
        connected: '/images/promo/desktop/BE/connected-fr.png'
      },
      nl: {
        welcome: {
          picture: '/images/promo/desktop/BE/welcome-nl.png',
          mobile: '/images/promo/desktop/BE/iphone-nl.png'
        },
        content: '/images/promo/desktop/BE/content-nl.png',
        disk: '/images/promo/desktop/BE/disk-nl.png',
        trackers: '/images/promo/desktop/BE/trackers-nl.png',
        forum: null,
        connected: '/images/promo/desktop/BE/connected-nl.png'
      }
    }
  },
  footer: {
    privacy: 'https://www.takeda.com/en-be/privacy-notice/',
    terms: 'https://www.takeda.com/terms-of-use/'
  },
  cardImages: {
    gastro: '/images/Gastro_Doctor.png',
    test: '/images/medical_test.png',
    call: '/images/nurse_call.png',
    visit: '/images/nurse_visit.png',
    practitioner: '/images/Doctor.png',
    intravenous: '/images/IV.png',
    default: '/images/appointment_tracker_2x.png'
  },
  injectionCard: {
    leftabdomen: '/images/injection-tracker/HB_SMALL_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_SMALL_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_SMALL_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_SMALL_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_SMALL_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_SMALL_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_SMALL_Track_Unconfirmed.jpg'
  },
  injectionTracker: {
    pickerValidation: false,
    leftabdomen: '/images/injection-tracker/HB_Track_RSA.jpg',
    rightabdomen: '/images/injection-tracker/HB_Track_LSA.jpg',
    leftarm: '/images/injection-tracker/HB_Track_BRA.jpg',
    rightarm: '/images/injection-tracker/HB_Track_BLA.jpg',
    leftleg: '/images/injection-tracker/HB_Track_FRT.jpg',
    rightleg: '/images/injection-tracker/HB_Track_FLT.jpg',
    default: '/images/injection-tracker/HB_Track_Default.jpg'
  },
  goHomeStyle: {
    display: 'none'
  },
  hcpStyle: {},
  types: {
    intravenous: [
      { code: 'INTRAVENOUS_INFUSION' },
      { code: 'GASTRO_SPECIALIST' },
      { code: 'GENERAL_PRACTITIONER' },
      { code: 'MEDICAL_TREATMENT_AT_HOSPITAL' },
      { code: 'COUNCELOR_OR_PSYCHOLOGIST_VISIT' },
      { code: 'NURSE_SUPPORT_CALL' },
      { code: 'NURSE_SUPPORT_VISIT' },
      { code: 'NUTRITIONIST_VISIT' },
      { code: 'MEDICAL_TEST' },
      { code: 'RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION' }
    ],
    subcutaneous: [
      { code: 'GASTRO_SPECIALIST' },
      { code: 'GENERAL_PRACTITIONER' },
      { code: 'MEDICAL_TREATMENT_AT_HOSPITAL' },
      { code: 'COUNCELOR_OR_PSYCHOLOGIST_VISIT' },
      { code: 'NURSE_SUPPORT_CALL' },
      { code: 'NURSE_SUPPORT_VISIT' },
      { code: 'NUTRITIONIST_VISIT' },
      { code: 'MEDICAL_TEST' },
      { code: 'RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION' }
    ],
    other: [
      { code: 'GASTRO_SPECIALIST' },
      { code: 'GENERAL_PRACTITIONER' },
      { code: 'MEDICAL_TREATMENT_AT_HOSPITAL' },
      { code: 'COUNCELOR_OR_PSYCHOLOGIST_VISIT' },
      { code: 'NURSE_SUPPORT_CALL' },
      { code: 'NURSE_SUPPORT_VISIT' },
      { code: 'NUTRITIONIST_VISIT' },
      { code: 'MEDICAL_TEST' },
      { code: 'RADIOLOGY_EXAMINATION_SCOPIC_EXAMINATION' }
    ]
  }
}
